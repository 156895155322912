<template>
  <bs-dialog
    class-names="tag-info-modal"
    close-on-escape
    close-on-backdrop-click
    mobile-floating
  >
    <template #header="{ close }">
      <div class="header d-flex justify-content-between align-items-center">
        <h5 class="title m-0">
          Course Tags
        </h5>
        <button
          class="button"
          @click="close"
        >
          <x-circle-icon />
        </button>
      </div>
    </template>

    <div class="modal_content my-4">
      <span
        v-for="tag in eventTags"
        :key="tag.abbreviation"
      >
        {{ tag.title }} ({{ tag.abbreviation }})<br>
      </span>
    </div>

    <template #footer="{ close }">
      <div class="footer float-end m-3 mt-1 me-0">
        <bar-button
          mode="outline"
          class="footer-button"
          @click="close"
        >
          &lt;&nbsp;&nbsp;Go Back
        </bar-button>
      </div>
    </template>
  </bs-dialog>
</template>

<script>
import BsDialog from '@/components/BsDialog/BsDialog.vue'
import XCircleIcon from '@/icons/XCircleIcon.vue'
import BarButton from '@/components/BarButton'
import { SessionListItemViewModel } from '../SessionListItem/SessionListItem.interfaces'

export default {
  components: {
    BsDialog,
    XCircleIcon,
    BarButton
  },
  props: {
    coachingSession: SessionListItemViewModel,
    eventTags: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>

.tag-info-modal {
  font-size: var(--barbri-base-font-size, 1rem);
  font-family: var(--babri-font-primary, "Proxima Nova"), sans-serif;
  $primary-color: #235ba8;

  .title {
    font-family: var(--babri-font-secondary, "Goldplay"), sans-serif;
    font-weight: 100 !important;
  }

  svg {
    vertical-align: unset !important;
    height: 32px;
    width: 32px;
  }

  .modal{
    &_content {
      line-height: 32px;
    }
  }

  .footer-button {
    line-height: 48px !important;
    border: 1px solid #1C75D4;
  }
}

.bs-dialog {
  --dialog-vertical: flex-start;
  --dialog-margin: auto;
  --dialog-gap: 16px;
  --dialog-backdrop-color: rgba(35, 91, 168, 0.32);
  --dialog-border-color: rgba(35, 91, 168, 0.4);
  --dialog-height: auto;
  --dialog-width: 80%;
}
</style>