import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c334843"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "coach-info-box px-3 pt-2 pb-0 p-md-3 pt-md-4 rounded-2"
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-3 ps-4 d-none d-md-block" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "col" }
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CirclePersonIcon = _resolveComponent("CirclePersonIcon")!

  return ($setup.coach)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            ($setup.profilePictureSource)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  class: "rounded-circle profile-image",
                  src: $setup.profilePictureSource
                }, null, 8, _hoisted_4))
              : (_openBlock(), _createBlock(_component_CirclePersonIcon, {
                  key: 1,
                  class: "profile-image"
                }))
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("b", null, "About Your Instructor: " + _toDisplayString($setup.coach.name), 1),
            _createElementVNode("p", {
              innerHTML: $setup.coach.aboutMe
            }, null, 8, _hoisted_6)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}